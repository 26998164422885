import { SectionList } from 'react-native';
import { styled, useStyled, propertyTokenMap } from '@gluestack-style/react';
export default styled(SectionList, {}, {
    componentName: 'SectionList',
    resolveProps: ['contentContainerStyle'],
}, {
    propertyResolver: {
        contentContainerStyle: (rawValue, resolver) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const aliases = useStyled()?.config?.aliases;
            const newValue = {};
            Object.entries(rawValue).forEach(([key, value]) => {
                if (Object.hasOwn(aliases, key)) {
                    newValue[`${aliases[key]}`] = resolver(value, 
                    //@ts-ignore
                    propertyTokenMap[aliases[key]]);
                }
                else {
                    //@ts-ignore
                    newValue[`${key}`] = resolver(value, propertyTokenMap[key]);
                }
            });
            rawValue = newValue;
            return rawValue;
        },
    },
});
