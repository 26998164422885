import { createStyle } from '@gluestack-style/react';
export const AvatarBadge = createStyle({
    w: '$5',
    h: '$5',
    bg: '$success500',
    borderRadius: '$full',
    position: 'absolute',
    right: 0,
    bottom: 0,
    borderColor: 'white',
    borderWidth: 2,
});
